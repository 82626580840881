import React from 'react';

import logo from '../assets/logo.svg';

const Hero = () => (
  <>
    <style>
      {`
        .hero {
          // color: white;
          color: #3a6d2c;
          // background-color: #fff;
        }
      `}
    </style>
    <div className="text-center hero my-5 hero">
      {/* <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" /> */}
      <h1 className="mb-4">Welcome</h1>

      <p className="lead">
        A central hub for all of your cloud identity needs.
      </p>
    </div>
  </>
);
export default Hero;
