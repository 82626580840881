import React, { useState, useEffect } from "react";
import { Button, Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import jwt_decode from "jwt-decode";

export const Applications = () => {
  const { apiOrigin = "http://localhost:3001", audience } = getConfig();

  const [accessToken, setAccessToken] = useState(null);
  const [decodedAccessToken, setDecodedAccessToken] = useState(null);

  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims } =
    useAuth0();

  const [apps, setApps] = useState(null);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        if (token) {
          setAccessToken(token);
          let decoded = jwt_decode(token);
          let { apps } = decoded;
          //   setDecodedAccessToken(apps);
          setApps(apps);
        }
      }
    })();
  }, []);

  return (
    <>
      <style>
        {`
          li {
            list-style-type: none;
          }
            .app-logo {
                height: 150px;
                width: 150px;
                border: 1px dotted black;
            }
                
            .app-div {
                // border: 1px red solid;
                background-color: #23241f;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                padding: 15px;
            }
            .app-logo:hover {
                opacity: 0.5;
            }
            .app-item {
              border: 2px solid #3a6d2c;
              padding: 2px;
            }
        `}
      </style>

      <div className="result-block-container">
        <h1>Applications</h1>
        <div>
          {apps && (
            <div className="inner-token-container">
              {/* <h6 className="muted">Applications</h6> */}
              <Highlight>{JSON.stringify(apps, null, 2)}</Highlight>
            </div>
          )}
        </div>
        <div className="app-div">
          {apps &&
            apps.map((app) => (
              <li key={`${app.name}`} className="app-item">
                <a href={`${app.url}`} target="_blank">
                  <img
                    className="app-logo"
                    src={`${app.logo}`}
                    alt={`${app.name}`}
                  />
                </a>
              </li>
            ))}
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Applications, {
  onRedirecting: () => <Loading />,
});
