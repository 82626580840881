import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import contentData from '../utils/contentData';

class Content extends Component {
  render() {
    return (
      <>
        <style>
          {`
          .content {
            text-align: center;
            // color: white;
            color: #3a6d2c;
            // background: #000;
            // background-color: #fff;
            width: 100%;
            // height: 100%;
            // padding: 30px;
          }
          ul {
            list-style-type: none;
            margin-bottom: 50px;
          }
        `}
        </style>

        <div className="next-steps my-5 content">
          <img src="https://imgs.search.brave.com/zv0Es_hrAon1fbkKxIMYV9-59ksL1m0ocJSFXFikavY/rs:fit:1200:1200:1/g:ce/aHR0cHM6Ly93d3cu/cGluY2xpcGFydC5j/b20vcGljZGlyL2Jp/Zy8xMDMtMTAzNTA0/NF9iYXRhdmlhLWxh/bmRzY2FwaW5nLXBh/dmluZy1hbmQtcGF0/aW9zLWxlYWYtY2ly/Y2xlLWxvZ28ucG5n" />
          {/* <h2 className="my-5 text-center">Welcome</h2> */}
          <h2 className="my-5">Expect more from your identity service provider</h2>
          <ul>
            <li>
              Does your current provider really understand what you're trying to
              do?
            </li>
          </ul>
          {/* <Row className="d-flex justify-content-between">
            {contentData.map((col, i) => (
              <Col key={i} md={5} className="mb-4">
                <h6 className="mb-3">
                  <a href={col.link} target="_blank">
                    <FontAwesomeIcon icon="link" className="mr-2" />
                    {col.title}
                  </a>
                </h6>
                <p>{col.description}</p>
              </Col>
            ))}
          </Row> */}
        </div>
      </>
    );
  }
}

export default Content;
