import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Highlight from "../components/Highlight";

// Check if the user has the right role...initiate another call to /authorize :)
const OrgManagement = () => {
  const { user, getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState(
    localStorage["accessToken"] || null
  );
  const [orgMembers, setOrgMembers] = useState(
    localStorage["org_members"] ? JSON.parse(localStorage["org_members"]) : []
  );
  const [org, setOrg] = useState(
    localStorage["org"] ? JSON.parse(localStorage["org"]) : null
  );
  const [allRoles, setAllRoles] = useState(
    localStorage["all_roles"] ? JSON.parse(localStorage["all_roles"]) : []
  );

  const api_domain = "https://atko-mv-server.vercel.app";
  const org_url = `${api_domain}/api/orgs`;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!accessToken) {
      (async () => {
        let at = await getAccessTokenWithPopup({
          authorizationParams: {
            organization: user.org_id,
            audience: "api://org-admin",
          },
        });
        localStorage["accessToken"] = at;
        setAccessToken(at);
        if (orgMembers.length === 0) {
          try {
            const options = {
              headers: {
                Authorization: `Bearer ${at}`,
              },
            };
            let res = await fetch(org_url, options);
            res = await res.json();
            let { org_members, org, all_roles } = res;
            setProgress(100);

            setOrgMembers(org_members);
            localStorage["org_members"] = JSON.stringify(org_members);

            setOrg(org);
            localStorage["org"] = JSON.stringify(org);

            setAllRoles(all_roles);
            localStorage["all_roles"] = JSON.stringify(all_roles);
          } catch (e) {
            console.log(e);
          }
        }
      })();
    }
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 95) {
          return 95;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const org_users = ["Mark"];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 9,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    color: "#3a6d2c",
    borderRadius: "25px",
  };

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("rol_hg4Vwo2Si3SgyCa7");
  const [modalOpen, setModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [inviteAlertOpen, setInviteAlertOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setInviteAlertOpen(false);
    setEmail("");
    setRole("rol_hg4Vwo2Si3SgyCa7");
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleInviteAlertClose = () => {
    setInviteAlertOpen(false);
  };

  const [invited, setInvited] = useState(false);

  const handleInvite = async () => {
    if (!email || !role) {
      setInviteAlertOpen(true);
      return;
    }
    setInvited(true);
    // Send user via custom api
    const org_url = `${api_domain}/api/orgs`;
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        invitee: email,
        invite_roles: [role],
      }),
    };
    let res = await fetch(org_url, options);
    res = await res.json();
    setInvited(false);
    setEmail("");
    setRole("rol_hg4Vwo2Si3SgyCa7");
    setAlertOpen(true);
    setModalOpen(false);
  };

  return (
    <div
      style={
        org && {
          color: "#3a6d2c",
          display: "flex",
          flexDirection: "column",
        }
      }
    >
      <style>
        {`
            input::placeholder {
                color: #3a6d2c;
            }
        `}
      </style>
      <h2
        style={org && {
          textAlign: "center",
          marginBottom: "50px",
          color: org.branding.colors.primary,
        }}
      >
        {org && org.display_name}
      </h2>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            flexDirection: "column",
            width: "50%",
            //   border: "1px green solid",
          }}
        >
          {org && (
            <div style={{ width: "100%" }}>
              <h4 style={{ textAlign: "center" }}>Org Metadata</h4>
              <Highlight>{JSON.stringify(org, null, 2)}</Highlight>
            </div>
          )}
          {/* <img src={org && org.branding.logo_url} />
        <h2
          style={
            org && {
              textAlign: "center",
              //   marginBottom: "20px",
              color: org.branding.colors.primary,
            }
          }
        >
          {org && org.display_name}
        </h2> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
          }}
        >
          {orgMembers.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                gap: "35px",
                width: "100%",
                height: "100%",
              }}
            >
              <h4>List of Org Members</h4>
              <ol>
                {orgMembers.map((member) => (
                  <li key={member.user_id}>{member.email}</li>
                ))}
              </ol>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={handleModalOpen}
                  style={{
                    margin: "auto",
                    left: "0",
                    right: "0",
                  }}
                >
                  Invite User
                </Button>
              </div>
            </div>
          ) : (
            <Box
              sx={{
                width: "33%",
                left: "0",
                right: "0",
                margin: "auto",
                top: "50%",
                position: "absolute",
              }}
            >
              <LinearProgress />
            </Box>
          )}
        </div>
      </div>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Invite a new user to your organization
          </Typography>
          <Snackbar
            open={inviteAlertOpen}
            autoHideDuration={3000}
            onClose={handleInviteAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleInviteAlertClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Could not invite user!
            </Alert>
          </Snackbar>
          <>
            {!invited ? (
              <>
                <input
                  name="email"
                  value={email}
                  placeholder="Enter User Email"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    padding: "7px",
                    width: "70%",
                    textAlign: "center",
                    color: "#3a6d2c",
                    border: "1px #3a6d2c solid",
                    borderRadius: "15px",
                  }}
                ></input>
                <select
                  name="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  style={{
                    padding: "9px",
                    width: "70%",
                    textAlign: "center",
                    color: "#3a6d2c",
                    border: "1px #3a6d2c solid",
                    borderRadius: "15px",
                  }}
                >
                  {allRoles &&
                    allRoles.map((role) => (
                      <option value={role.id}>{role.name}</option>
                    ))}
                  {/* <option value="rol_hg4Vwo2Si3SgyCa7">Guest</option>
                  <option value="rol_8tm4zGsn501KicBd">Dashboard User</option> */}
                </select>
                <Button onClick={handleInvite}>Send Invite</Button>
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </>
        </Box>
      </Modal>
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Successfully invited user!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrgManagement;
