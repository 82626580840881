import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'reactstrap';
import Highlight from '../components/Highlight';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from '../config';
import Loading from '../components/Loading';
import jwt_decode from 'jwt-decode';

export const Permissions = () => {
  const { apiOrigin = 'http://localhost:3001', audience } = getConfig();

  const [accessToken, setAccessToken] = useState(null);
  const [decodedAccessToken, setDecodedAccessToken] = useState(null);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        setAccessToken(token);
        let decoded = jwt_decode(token);
        setDecodedAccessToken(decoded);
      }
    })();
  }, []);

  return (
    <>
      <style>
        {`
          .api-container {
            color: #3a6d2c;
          }
          .token-container {
            // border: 1px red solid;
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-around;
          }
          .inner-token-container {
            width: 49%;
            // border: 1px green solid;
          }
          .token-textarea {
            width: 100%;
            height: 700px;
            background: black;
            color: yellow;
            // border: none;
            padding: 20px;
            resize: none;
            border: 1px white solid;
            font-size: 15px;
            text-wrap: wrap;
          }
          .muted {
            color: #3a6d2c;
          }
        `}
      </style>

      <div className="result-block-container">
        {accessToken && (
          <div className="token-container">
            <div className="inner-token-container">
              <h6 className="muted">Access Token</h6>
              <Highlight>{JSON.stringify(accessToken, null, 2)}</Highlight>
            </div>
            {decodedAccessToken && (
              <div className="inner-token-container">
                <h6 className="muted">Decoded Access Token</h6>
                <Highlight>
                  {JSON.stringify(decodedAccessToken, null, 2)}
                </Highlight>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(Permissions, {
  onRedirecting: () => <Loading />,
});
